export const SecondDay = [
    {
        time: "12:30am",
        event: 'Midnight Snacks',
        location: 'Business Building Lobby.',
        //description: 'Krispy Kreme',
    },
    {
        time: "7:00am",
        event: 'Breakfast',
        location: 'Business Building Lobby',
        description: 'Assorted Breakfast',
    },
    {
        time: "10:00am - 11:00am",
        event: 'Workshop',
        location: 'T.B.D.',
        description: 'T.B.D',
    },
    {
        time: "12:00pm",
        event: 'Lunch',
        location: 'Business Building Lobby',
        //description: "Wendy's",
    },
    {
        time: "4:30pm",
        event: 'Project Submissions Due',
        location: '',
        description: 'Make sure everything is submitted by 4:30pm. We will not accept late submissions. ' +
            'Submit projects to our',
        linkWord: 'Devpost.',
        link: 'https://calvinhacks-2024.devpost.com/?ref_feature=challenge&ref_medium=discover',
    },
    // {
    //     time: "5:00pm",
    //     event: 'Hacking Ends',
    //     location: '',
    //     description: '',
    // },
    {
        time: "5:00pm - 6:00pm",
        event: 'Project Expo',
        location: 'Business Building Lobby',
        description: 'Showcase projects exposition-style to be judged.',
    },
    {
        time: "6:30pm",
        event: 'Dinner',
        location: 'Business Building Lobby',
        //description: 'Wingstop',
    },
    // {
    //     time: "7:30pm",
    //     event: 'Finalists Selected',
    //     location: 'Commons Annex Basement',
    //     description: 'Announcing the projects/teams that will move to the final round.',
    // },
    {
        time: "7:30pm",
        event: 'Announce Winners and Hand Out Prizes',
        location: 'Business Building Lobby',
        description: 'Prizes and projects can be viewed at our',
        linkWord: 'Devpost.',
        link: 'https://calvinhacks-2024.devpost.com/?ref_feature=challenge&ref_medium=discover',
    },
    {
        time: "8:00pm",
        event: 'Closing Ceremony',
        location: 'Business Building Lobby',
        description: 'Wrapping things up!',
    },
    // {
    //     time: "6:50pm - 7:20pm",
    //     event: 'Finalist Presentations (Judging Round 2)',
    //     location: 'Commons Annex Basement',
    //     description: 'Finalists present their projects to the panel of judges.',
    // },
    {
        time: "8:15pm",
        event: 'Hacking Ends',
        location: 'Business Building Lobby',
        description: '',
    },
    {
        time: "8:30pm",
        event: 'Venue Closes for Participants',
        location: 'Business Building Lobby',
        description: 'Thank you and we hope to see you again next year!',
    },
]